import { Query } from '@datorama/akita';
import { IShellState } from './IShellState';
import { ShellStore } from '@shared/store/shell.store';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { IShellContext } from './IShellContext';
import { RoleTypeEnum } from '@shared/enums';
import { Order } from 'app/doctors/orders/orders.service';

@Injectable({ providedIn: 'root' })
export class ShellQuery extends Query<IShellState> {
	context$: Observable<IShellContext> = this.select((state) => state.context);

	selectedCompanyId$: Observable<number> = this.select((state) => +state.context.bizCtx.companyId);
	selectedLanguage$: Observable<string> = this.select((state) => state.context.UI.language);
	roleType$: Observable<RoleTypeEnum> = this.select((state) => state.context.user.roleType);
	isAuth0$: Observable<boolean> = this.select((state) => state.isAuth0);
	
	order$: Observable<Order> = this.select((state) => state.order);
	constructor(protected store: ShellStore) {
		super(store);
	}
}
