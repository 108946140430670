import { Injectable } from '@angular/core';
import { ShellQuery } from '@shared/store/shell.query';
import { createInitialState, ShellStore } from '@shared/store/shell.store';
import { IShellContext, IShellContextBizCtx, IShellContextUI  } from '@shared/store/IShellContext';
import { Observable, Subject, take, tap } from 'rxjs';
import { RoleTypeEnum } from '@shared/enums';
import _ from 'lodash';
import { Order } from 'app/doctors/orders/orders.service';

@Injectable({ providedIn: 'root' })
export class ShellContextService {
	selectedLanguage$: Observable<string> = this.shellQuery.selectedLanguage$;
	
	contextBizContextChanged$ = new Subject<IShellContextBizCtx>();
	contextUiChanged$ = new Subject<IShellContextUI>();

	constructor(private shellQuery: ShellQuery,
				private shellStore: ShellStore) {
	}

	get selectedCompanyId$(): Observable<number> {
		return this.shellQuery.selectedCompanyId$;
	}

	get roleType$(): Observable<RoleTypeEnum> {
		return this.shellQuery.roleType$;
	}

	get isAuth0$(): Observable<boolean> {
		return this.shellQuery.isAuth0$;
	}

	getContext(): Observable<IShellContext> {
		return this.shellQuery.context$;
	}
	
	getOrder(): Observable<Order> {
		return this.shellQuery.order$;
	}

	updateOrder(order: Order): void {
		this.shellStore.update(state => {
			state.order = order;
		});
	}

	updateContext(contextCallback: (context: IShellContext) => void): void;
	updateContext(partialContext: Partial<IShellContext>): void;
	updateContext(contextOrCallback: ((context: IShellContext) => void) | Partial<IShellContext> ): void {
		if (typeof contextOrCallback === 'function') {
			this.shellStore.update(state => {	
				let originalBizCtxSection = _.cloneDeep(state.context.bizCtx);
				let originalUiSection = _.cloneDeep(state.context.UI);		
				contextOrCallback(state.context);
				this.triggerContextBizCtxUpdatedIfNeed(originalBizCtxSection, _.cloneDeep(state.context.bizCtx));
				this.triggerContextUiUpdatedIfNeed(originalUiSection, _.cloneDeep(state.context.UI));
			});
		} else {
			this.shellStore.update(state => {
				let originalBizCtxSection = _.cloneDeep(state.context.bizCtx);
				let originalUiSection = _.cloneDeep(state.context.UI);	
				state.context = { ...state.context, ...contextOrCallback };
				this.triggerContextBizCtxUpdatedIfNeed(originalBizCtxSection, _.cloneDeep(state.context.bizCtx));
				this.triggerContextUiUpdatedIfNeed(originalUiSection, _.cloneDeep(state.context.UI));
			});
		}
	}

	private triggerContextBizCtxUpdatedIfNeed(originalBizCtxSection: IShellContextBizCtx, newBizCtxSection: IShellContextBizCtx) {
		let bizCtxChanged = !_.isEqual(originalBizCtxSection, newBizCtxSection);
		if (bizCtxChanged)
			this.contextBizContextChanged$.next(newBizCtxSection);
	}

	private triggerContextUiUpdatedIfNeed(originalUiSection: IShellContextUI, newUiSection: IShellContextUI) {
		let uiSectionChanged = !_.isEqual(originalUiSection, newUiSection);
		if (uiSectionChanged)
			this.contextUiChanged$.next(newUiSection);
	}

	resetContext() {
		this.shellStore.update(state => {
			state.context = createInitialState().context;
			state.order = createInitialState().order;
		});
	}

	resetContextWithoutSession() {
		this.getContext().pipe(take(1), tap(context => {
			let sessionSection = context.session;
			let securitySection = context.security;
			this.shellStore.update(state => {
				state.context = createInitialState().context;
				state.context.session = sessionSection;
				state.context.security = securitySection;
			});
		})).subscribe();
	}

	resetOrderData() {
		const orderData = { orderId: null, csmMessageId: null, patientId: null, rxId: null };
		this.shellStore.update(state => {
			state.context.bizCtx = { ...state.context.bizCtx, ...orderData };
			state.order = null;
		});
	}
}
